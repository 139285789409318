class SimpleroTestimonialSwitcher {
  constructor(elm) {
    this.randomTestimonialIndex = this.randomTestimonialIndex.bind(this)
    this.image = this.image.bind(this)
    this.content = this.content.bind(this)
    this.addAnimationClass = this.addAnimationClass.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.autoToggleNextTestimonial = this.autoToggleNextTestimonial.bind(this)
    this.elm = elm
    this.testimonials = this.elm.querySelectorAll(
      '.simplero-new-hero-with-testimonials__testimonial_container__testimonial'
    )

    // When delay is 0, we just pick one and show that for the duration of the page load, hence the `|| 1000000`
    this.switchDelay =
      (parseInt(this.elm.dataset.switchTestimonialDelay, 10) || 10000000) * 1000

    if (this.testimonials.length === 0) {
      return
    }

    if (this.elm.dataset.renderMode == 'live_edit') {
      this.testimonials[0].classList.add('testimonial--showing')
      this.elm.addEventListener('simplero:block:select', (e) => {
        if (e.target) {
          this.elm
            .querySelectorAll('.testimonial--showing')
            .forEach((el) => el.classList.remove('testimonial--showing'))
          e.target.classList.add('testimonial--showing')
        }
      })
    } else {
      // for the first time
      this.autoToggleNextTestimonial()
    }
  }

  randomTestimonialIndex() {
    if (this.testimonials.length === 1) {
      return 0
    }
    return Math.round((this.testimonials.length - 1) * Math.random())
  }

  image(testimonial) {
    return testimonial.querySelector(
      '.simplero-new-hero-with-testimonials__testimonial_container__image'
    )
  }

  content(testimonial) {
    return testimonial.querySelector(
      '.simplero-new-hero-with-testimonials__testimonial_container__content'
    )
  }

  addAnimationClass(testimonial, className, cb) {
    this.image(testimonial).classList.add(className)
    this.content(testimonial).classList.add(className)

    return setTimeout(() => {
      this.image(testimonial).classList.remove(className)
      this.content(testimonial).classList.remove(className)
      if (cb) {
        return cb()
      }
    }, 500)
  }

  show(testimonial) {
    testimonial.classList.add('testimonial--showing')
    return this.addAnimationClass(testimonial, 'enter')
  }

  hide(testimonial) {
    const callback = () => {
      return testimonial.classList.remove('testimonial--showing')
    }

    return this.addAnimationClass(testimonial, 'exit', callback)
  }

  autoToggleNextTestimonial() {
    if (this.currentShowing) {
      this.hide(this.currentShowing)
    }

    this.currentShowingIndex = this.getNextShowingIndex()

    this.currentShowing = this.testimonials[this.currentShowingIndex]
    this.removeLaziness(this.currentShowing)

    setTimeout(() => {
      return this.show(this.currentShowing)
    }, 100)

    if (this.testimonials.length > 1) {
      setTimeout(this.autoToggleNextTestimonial, this.switchDelay)

      // Remove loading-lazy from the next one so that it's loaded when ready.
      if (this.switchDelay < 10000000000) {
        this.removeLaziness(this.testimonials[this.getNextShowingIndex()])
      }
    }
  }

  getNextShowingIndex() {
    return this.currentShowingIndex < this.testimonials.length - 1
      ? this.currentShowingIndex + 1
      : this.currentShowingIndex
      ? 0
      : this.randomTestimonialIndex()
  }

  removeLaziness(testimonial) {
    testimonial
      .querySelectorAll("img[loading='lazy']")
      .forEach((el) => el.removeAttribute('loading'))
  }
}

const initialize = () => {
  document
    .querySelectorAll(
      '.simplero-new-hero-with-testimonials__testimonial_container'
    )
    .forEach((el) => new SimpleroTestimonialSwitcher(el))
}

document.addEventListener('DOMContentLoaded', initialize)
document.addEventListener('simplero:section:load', initialize)
